toggleCityAndPostcodeFields = ->
  selectedOption = $('#panel_change_request_panel_to_id option:selected')

  requiresPostcode = selectedOption.data('requires-postcode')

  if requiresPostcode
    $('#city_and_postcode_fields').show()
  else
    $('#city_and_postcode_fields').hide()

$(document).on 'turbolinks:load', ->
  selectElement = $('#panel_change_request_panel_to_id')
  if selectElement.length > 0
    selectElement.on 'change', toggleCityAndPostcodeFields

    toggleCityAndPostcodeFields()
